<template>
  <v-container>
    <v-row class="pt-10" justify="center">
      <v-col cols="11">
        <v-sheet outlined rounded="lg">
          <v-row class="pa-4" justify="center">
            <v-col cols="2">
              <v-img
                src="https://drive.google.com/uc?export=view&id=1ImOCC6cRBxOV5j2zwEJ0GTOn9TYOPkIF"
              >
              </v-img>
            </v-col>
            <v-col cols="9">
              <v-row class="mx-0 pt-8">
                <span class="subtitle-1 primary-variant font-weight-black">
                  Ups! Hubo un problema con la transacción.
                </span>
              </v-row>
              <v-row class="mx-0 pb-8">
                <span class="body-2 primary-variant mt-2">
                  Reintenta nuevamente para continuar con tu activación.
                </span>
              </v-row>
              <v-row class="mx-0">
                <span
                  class="caption primary-variant mt-8 mr-4 font-weight-bold"
                >
                  JUAN ERNESTO - LOPEZ MARTINEZ
                </span>
                <span class="caption primary-variant mt-8 mr-4">
                  <span class="font-weight-bold">F. Inicio:</span> 01/01/2021
                </span>
              </v-row>
              <v-row class="mx-0">
                <span class="caption primary-variant mt-2 mr-4">
                  <span class="font-weight-bold">Cargo:</span> Asesor Comercial
                  Ventas
                </span>
                <span class="caption primary-variant mt-2 mr-4">
                  <span class="font-weight-bold">Usuario:</span>
                  JuanLopez@gmail.com
                </span>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mx-4 py-8" justify="end">
            <v-btn
              small
              rounded
              color="primary"
              class="mr-2"
              @click="$emit('cancel')"
            >
              <span class="caption"> Cerrar </span>
            </v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>