<template>
  <v-container>
    <v-row class="pt-10" justify="center">
      <v-col cols="11">
        <v-sheet outlined rounded="lg">
          <v-row class="pa-4" justify="center" align="center">
            <v-col cols="2">
              <v-img
                src="https://drive.google.com/uc?export=view&id=1ayHBNWo0evV1zybw2TLy1kds6AORxjVc"
              >
              </v-img>
            </v-col>
            <v-col cols="9">
              <v-row class="mx-0">
                <span class="subtitle-1 primary-variant font-weight-black">
                  Registra los datos del nuevo afiliado vinculado
                </span>
                <br />
                <span class="body-2 primary-variant mt-2">
                  Luego de Registrar los datos para el nuevo afiliado vinculado,
                  deberás cubrir las cuotas para el pago local y nacional
                </span>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="3" class="mx-1 pa-0 my-1">
              <v-text-field
                solo
                dense
                hide-details="auto"
                v-model="newAffiliate.user.name"
              >
                <template v-slot:label>
                  <span class="caption"> Nombres </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3" class="mx-1 pa-0 my-1">
              <v-text-field
                solo
                dense
                hide-details="auto"
                v-model="newAffiliate.user.firstLastname"
              >
                <template v-slot:label>
                  <span class="caption"> Apellidos </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="3" class="mx-1 pa-0 my-1">
              <v-text-field
                solo
                dense
                hide-details="auto"
                v-model="newAffiliate.user.email"
              >
                <template v-slot:label>
                  <span class="caption"> Email </span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="3" class="mx-1 pa-0 my-1">
              <v-text-field
                solo
                dense
                hide-details="auto"
                v-model="newAffiliate.user.position"
              >
                <template v-slot:label>
                  <span class="caption"> Cargo </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-4 py-8" justify="end">
            <v-btn small rounded text class="mr-2" @click="$emit('cancel')">
              <span class="caption"> Cancelar </span>
            </v-btn>
            <v-btn
              small
              rounded
              color="primary"
              class="white--text mr-2"
              @click="AddAffiliate(false)"
            >
              <span class="caption"> Crear y salir </span>
              <v-icon size="14" class="ml-2"> mdi-content-save </v-icon>
            </v-btn>
            <v-btn
              small
              rounded
              color="primary"
              class="white--text"
              @click="AddAffiliate(true)"
            >
              <span class="caption"> Crear e iniciar pago </span>
              <v-icon size="12" class="ml-2"> mdi-arrow-expand-right </v-icon>
            </v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "RegisterForm",
  data() {
    return {
      newAffiliate: {
        user: {
          name: "",
          firstLastname: "",
          email: "",
          position: "",
        },
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("membershipProfile", ["userMembership"]),
  },
  methods: {
    ...mapActions("memberAffiliates", [
      "getAffiliates",
      "addAffiliate",
      "deleteAffiliate",
      "updateAffiliate",
    ]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),

    async AddAffiliate(continuePayment) {
      try {
        this.loading();
        const affiliate = await this.addAffiliate({
          affiliate: this.newAffiliate,
          organization: this.user.headOffice._id,
          branch: this.userMembership.organization._id,
        });
        console.log(affiliate);
        if (affiliate.status) {
          this.show({ text: "Afiliado creado exitosamente", color: "green" });
          if (continuePayment) {
            const user = affiliate.affiliate;
            this.$router.push(
              `/member/newAffiliate/2/${user._id}/${this.userMembership.organization._id}`
            );
          } else {
            //await this.realoadUserData({ email: this.user.email });
            this.$emit("cancel");
          }
        } else {
          this.show({
            text: affiliate.message,
            color: "red",
          });
          this.$emit("error");
        }
        this.loaded();
      } catch (error) {
        console.log(error);
      } finally {
      }
    },
  },
};
</script>

<style></style>