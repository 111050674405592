<template>
  <v-container>
    <v-row>
      <v-btn text small class="caption" to="/member/affiliates" v-if="!$route.path.includes('renew')">
        <v-icon>mdi-chevron-left</v-icon>
        volver
      </v-btn>
    </v-row>
    <RegisterForm v-if="setStep == 1" @continue="setStep++" @cancel="goBack" @error="setStep = 0" />
    <ContinuePayment v-if="setStep == 2 && this.membershipId" :affiliation-id="this.affiliationId"
      :membershipId="this.membershipId" @cancel="goBack" />
    <Error v-if="setStep == 0" @cancel="goBack" />
  </v-container>
</template>

<script>
import RegisterForm from "./steps/RegisterForm.vue";
import ContinuePayment from "./steps/ContinuePayment.vue";
import Error from "./steps/Error.vue";
export default {
  props: ["setStep", "membershipId", "affiliationId"],
  components: {
    RegisterForm,
    ContinuePayment,
    Error,
  },
  mounted() {
    //this.step = this.setStep
  },
  methods: {
    goBack() {
      this.$router.push({ name: "Member Affiliates" })
    }
  },
  data() {
    return {
      step: 1,
    };
  },
};
</script>

<style></style>